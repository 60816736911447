@font-face {
    font-family: 'Garet';
    src: url('./font/Garet-Heavy.woff2') format('woff2'),
         url('./font/Garet-Heavy.woff') format('woff');
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    background-color: #000;
    color: #fff;
    height: 100%;
}

.container-fluid {
    max-width: 100%;
}

.bg-black {
    background-color: #000;
}

.text-blue {
    color: #007BFF;
}

.text-black {
    color: #333;
}

.hero-section {
    min-height: 90vh;
    background-color: #000;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    align-items: center;
    text-align: left;
}

.hero-section h1 {
    font-size: 4.5rem;
    color: #fff;
    margin-bottom: 0.5rem;
}

.hero-section h3,
.hero-section p,
.hero-section a {
    color: #fff;
    font-size: 1.2rem;
}

.hero-logo {
    text-align: center;
}

.hero-logo img {
    max-width: 100%;
}

.btn-primary {
    background-color: #007BFF;
    border: none;
    padding: 0.75rem 2rem;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.navbar,
.footer {
    background-color: #000;
    color: #fff;
    padding: 1rem 0;
}

/* Navbar Styles */
.navbar {
    background-color: #000 !important; /* Ensure navbar is always black */
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-link,
.navbar-brand {
    color: #fff !important;
    text-decoration: none;
    margin-right: 1rem;
    position: relative;
    transition: color 0.3s ease-in-out;
}

.nav-link:hover::after,
.nav-link.active-link::after,
.navbar-brand:hover::after,
.navbar-brand.active-link::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #007BFF; /* Blue underline */
    position: absolute;
    bottom: -5px;
    left: 0;
}

.nav-link:hover,
.nav-link.active-link {
    color: #fff; /* Keep text white on hover and active */
}

.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Responsive design adjustments */
@media only screen and (max-width: 767px) {
    .desktop-nav {
        display: none;
    }

    .mobile-nav {
        display: block;
    }
}

@media only screen and (min-width: 768px) {
    .mobile-nav {
        display: none;
    }

    .desktop-nav {
        display: flex;
    }
}

.custom-circle-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

/* Footer Styles */
.footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    position: relative;
}

.footer-line {
    background-color: white;
    height: 2px;
    width: 100%;
}

.footer-line-blue {
    background-color: #007BFF;
    height: 2px;
    width: 100%;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-logo img {
    max-width: 200px;
}

.footer-links ul {
    list-style-type: none;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 10px;
}

.footer-links ul li a {
    color: #fff; /* White text */
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links ul li a:hover {
    color: #007BFF; /* Blue on hover */
}

/* Global styles */
body, html {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
}

/* Specific to About page */
.container-fluid {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
}

h1, h2, h4, p {
    color: #fff; /* Ensure all text is white */
}

.text-secondary {
    color: #fff !important; /* Override any specific styles */
}

.btn-primary {
    background-color: #007BFF;
    border: none;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.about-section,
.services-section,
.contact-section {
    padding: 2rem;
    color: #fff;
    background-color: #000;
}

h1, h2, .contact-section h1, .contact-section p {
    color: #007BFF;
}

.accordion-button {
    color: #000;
}

.accordion-button:not(.collapsed) {
    color: #007BFF;
    background-color: #fff;
}

.accordion-body {
    background-color: #f8f9fa;
    color: #000;
}

.form-group {
    color: #fff; /* White text */
}

.form-group label {
    color: #fff; /* White label text */
}

/* About Page Styles */
.container-fluid {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
}

.text-secondary {
    color: #fff; /* White text */
}

h1, h2, h4 {
    color: #fff; /* White text */
}

p {
    color: #fff; /* White text */
}

.btn-primary {
    background-color: #007BFF;
    border: none;
}

.btn-primary:hover {
    background-color: #0056b3;
}

@media only screen and (max-width: 767px) {
    .desktop-nav {
        display: none;
    }
    
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-menu, .nav-contact {
        flex-direction: row;
        justify-content: center;
        padding: 0;
        margin-top: 1rem;
    }

    .hero-section {
        text-align: center;
    }

    .hero-logo {
        order: -1;
        margin-bottom: 2rem;
    }

    .hero-logo img {
        max-width: 80%;
    }

    .hero-content {
        order: 2;
        padding: 0 15px;
    }

    .hero-section h1 {
        font-size: 2rem;
    }

    .hero-section p {
        font-size: 1rem;
    }

    .btn-primary {
        padding: 0.5rem 1.5rem;
    }

    .nav-icon img {
        width: 20px;
    }

    .nav-menu, .nav-contact {
        justify-content: space-around;
        width: 100%;
    }

    .navbar .nav-contact {
        position: static;
        flex-direction: row;
        justify-content: flex-end;
    }
}

@media only screen and (min-width: 768px) {
    .mobile-nav {
        display: none;
    }
}
